const SPORT_ID = "4";
const COMPETITION_ID = "101480";
const EVENT_ID = "28127348";
const ROUTE_HASH = `${SPORT_ID}:${COMPETITION_ID}:${EVENT_ID}`;
const KINGS_XI_PUNJAB_RUNNER_ID = "2954262";

export const IPL = {
    sportId: SPORT_ID,
    competitionId: COMPETITION_ID,
    eventId: EVENT_ID,
    routeHash: ROUTE_HASH,
    blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};
