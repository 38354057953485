import moment, { Moment } from 'moment';
import 'moment-timezone';

export interface DateFilter {
  startDate: Moment;
  endDate: Moment;
}

// default time zone
const timezone = 'Asia/Calcutta';

const toString = (time: Moment) => time.format('yyyy-MM-DD HH:mm:ss');

const timeFromString = (time: String) => moment.tz(time, timezone);

export const now = () => moment().tz(timezone);

export const startOfToday = () => now().startOf('day');

export const endOfToday = () => now().endOf('day');

export const minDate = () => startOfToday().subtract(2, 'months');

export const getIndianTime = (time) => moment.tz(time, 'Asia/Calcutta');

export const getCurrentIndianTime = () => moment.tz('Asia/Calcutta');

export const startOfDay = (time: Moment) =>
  timeFromString(toString(time)).startOf('day');

export const endOfDay = (time: Moment) =>
  timeFromString(toString(time)).endOf('day');

export const getTime = (date, time) => {
  return timeFromString(
    date.format('yyyy-MM-DD') + ' ' + time.format('HH:mm:ss')
  );
};

export const getTimeInDefaultTimezone = (format) => {
  return now().format(format);
};

export const convertTimeToDefaultTimezone = (time) => {
  return moment(time).tz(timezone);
};

export const getDateLabel = (time: String) => {
  return timeFromString(time).format('DD/MM/YYYY');
};

export const dateRanges: any = {
  Today: [startOfToday(), endOfToday()],
  Yesterday: [
    startOfToday().subtract(1, 'days'),
    endOfToday().subtract(1, 'days'),
  ],
  'Last 7 Days': [startOfToday().subtract(6, 'days'), endOfToday()],
  'This Month': [now().startOf('month'), endOfToday()],
  'Last Month': [
    now().subtract(1, 'month').startOf('month'),
    now().subtract(1, 'month').endOf('month'),
  ],
};

export const pickerOptions: any = {
  startDate: startOfToday(),
  endDate: endOfToday(),
  ranges: dateRanges,
  autoApply: true,
  minDate: minDate(),
  maxDate: endOfToday(),
};
