import API from '../../api';
import { FETCH_MARKET_ANALYSIS_DATA_SUCCESS } from './dashboardActionTypes';

const fetchMarketAnalysisDataSuccess = (payload) => {
  return {
    type: FETCH_MARKET_ANALYSIS_DATA_SUCCESS,
    payload: payload,
  };
};

export const fetchMarketAnalysisData = () => {
  return async (dispatch: Function) => {
    try {
      const response = await API.get(
        `/admin/reports/dashboard/market-analysis`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        dispatch(fetchMarketAnalysisDataSuccess(response.data));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
};