import API from '../../api';
import { logout } from '../auth/authActions';

import {
  SET_BETS,
  SET_MULTI_BET_STAKE_POINTS,
  SET_ACCEPT_NEW_ODDS,
  TOGGLE_BETSLIP,
  CLEAR_BETS,
  FETCH_STAKE_LIMITS_SUCCESS,
  FETCH_STAKE_LIMITS_FAILED,
  SET_QB_ACTIVE,
} from './betslipActionTypes';
import { PlaceBetRequest } from '../../models//api/PlaceBetRequest';
import { StakeLimits } from '../../models/StakeLimits';

export const setQbActive = (outcomeId: string) => {
  return {
    type: SET_QB_ACTIVE,
    payload: outcomeId,
  };
};

export const setBetRequests = (bets: PlaceBetRequest[] | []) => {
  return {
    type: SET_BETS,
    payload: bets,
  };
};

export const setMultiBetStakePoints = (points: number) => {
  return {
    type: SET_MULTI_BET_STAKE_POINTS,
    payload: points,
  };
};

export const setAcceptNewOdds = (val: boolean) => {
  return {
    type: SET_ACCEPT_NEW_ODDS,
    payload: val,
  };
};

export const toggleBetslip = (val: boolean) => {
  return {
    type: TOGGLE_BETSLIP,
    payload: val,
  };
};

export const clearBetHandler = (outcomeId: string) => {
  return {
    type: CLEAR_BETS,
    payload: outcomeId,
  };
};

const fetchStakeLimitsSuccess = (stakeLimits: StakeLimits) => {
  return {
    type: FETCH_STAKE_LIMITS_SUCCESS,
    payload: stakeLimits,
  };
};

const fetchStakeLimitsFailed = () => {
  return {
    type: FETCH_STAKE_LIMITS_FAILED,
  };
};

export const fetchStakeLimits = () => {
  return async (dispatch: Function) => {
    try {
      const response = await API.get('/user/stake-limits', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      dispatch(fetchStakeLimitsSuccess(response.data));
    } catch (err) {
      dispatch(fetchStakeLimitsFailed());
      if (err.response && err.response.status === 401) {
        const token = sessionStorage.getItem('jwt_token');
        if (token) {
          dispatch(logout());
        }
      }
    }
  };
};
