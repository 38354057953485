import { MatchOddsDTO } from '../../models/common/MatchOddsDTO';
import {
  EventsMap,
  SecondaryMarketsMap,
  SecondaryMatchOddsMap,
} from '../../models/ExchangeSportsState';

export const getAllMarketsByEvent = (
  allEvents: EventsMap,
  eventTypeId: string,
  competitionId: string,
  eventId: string
) => {
  if (
    allEvents[eventTypeId] &&
    allEvents[eventTypeId][competitionId] &&
    allEvents[eventTypeId][competitionId][eventId]
  ) {
    return allEvents[eventTypeId][competitionId][eventId];
  }
  return null;
};

export const getBookmakerMarketsByEvent = (
  secondaryMarketsMap: SecondaryMarketsMap,
  eventId: string
) => {
  if (secondaryMarketsMap[eventId]) {
    return secondaryMarketsMap[eventId].bookmakers;
  }
  return null;
};

export const getFancyMarketsByEvent = (
  secondaryMarketsMap: SecondaryMarketsMap,
  eventId: string
) => {
  if (secondaryMarketsMap[eventId]) {
    return secondaryMarketsMap[eventId].fancyMarkets.sort((a, b) => {
      const aDesc = a.marketName;
      const bDesc = b.marketName;
      if (aDesc > bDesc) return 1;
      else if (aDesc < bDesc) return -1;
      return 0;
    });
  }

  return null;
};

export const isFancyMarketSuspended = (
  secondaryMarketsMap: SecondaryMarketsMap,
  eventId: string
) => {
  if (secondaryMarketsMap[eventId]) {
    return secondaryMarketsMap[eventId].fancySuspended;
  }
  return false;
};

export const getSecondaryMarketsByEvent = (
  secondaryMarketsMap: SecondaryMarketsMap,
  eventId: string
) => {
  if (secondaryMarketsMap[eventId]) {
    return secondaryMarketsMap[eventId];
  }
  return null;
};

export const getSecondaryMatchOddsByEvent = (
  secondaryMatchOddsMap: SecondaryMatchOddsMap,
  eventId: string
) => {
  let secondaryMatchOdds: MatchOddsDTO[] = [];
  for (let mo of Object.keys(secondaryMatchOddsMap)) {
    if (mo.startsWith(eventId)) {
      secondaryMatchOdds.push(secondaryMatchOddsMap[mo]);
    }
  }
  secondaryMatchOdds.sort((a, b) => {
    const aDesc = a.marketName;
    const bDesc = b.marketName;
    if (aDesc > bDesc) return 1;
    else if (aDesc < bDesc) return -1;
    return 0;
  });
  return secondaryMatchOdds;
};
