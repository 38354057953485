import { BACKEND_DOMAIN } from '../constants/branding';

export const DevUrls = {
  REACT_APP_REST_API_URL: `https://stage-api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_V2_API_URL: `https://stage-api.${BACKEND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.${BACKEND_DOMAIN}`,
  REACT_APP_REST_AWS_API: `https://1dx3t3wxu5.execute-api.eu-west-2.amazonaws.com/v1`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.${BACKEND_DOMAIN}/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://stage-user-api.hypexexch.com/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://stage-agpay.${BACKEND_DOMAIN}`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_V2_API_URL: `https://api.${BACKEND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BACKEND_DOMAIN}`,
  REACT_APP_REST_AWS_API: `https://1dx3t3wxu5.execute-api.eu-west-2.amazonaws.com/v1`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BACKEND_DOMAIN}/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/odds-feed`,
  REACT_APP_REST_AGPAY_URL: `https://agpay.${BACKEND_DOMAIN}`,
};

export const EnvUrlsMap = {
  development: DevUrls,
  stage: DevUrls,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
